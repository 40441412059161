
import { Component, mixins } from 'nuxt-property-decorator';
import DeviceMixin from '~/mixins/device.mixin';
import WidgetMixin from '~/mixins/widget.mixin';
import ProductResults from '~/models/product-results';
import Photo from '~/models/photo';
import { plainToInstance } from 'class-transformer';
import Banner from '~/models/banner';
import { formatDate } from '~/utils/date-helper';
import Swiper from 'swiper';

@Component({
  name: 'dn-three-leaflet-with-banner-slider',
})
export default class ThreeLeafletWithBannerSlider extends mixins(
  DeviceMixin,
  WidgetMixin,
) {
  private swiper: Swiper | null = null;

  private get swiperName(): string {
    return 'swiper-minibannerscarousel';
  }

  mounted() {
    this.$nextTick(() => {
      if (this.slides.length > 1) {
        this.initSwiper();
      }
    });
  }

  beforeDestroy() {
    window.removeEventListener('load', this.initSwiper);
    if (this.swiper) {
      this.swiper.destroy?.();
      this.swiper = null;
    }
  }

  initSwiper() {
    if (document.readyState === 'complete') {
      this.createSwiper();
    } else {
      window.addEventListener('load', this.createSwiper);
    }
  }

  createSwiper() {
    this.swiper = new Swiper(`#${this.swiperName}`, {
      slidesPerView: 'auto',
    });
  }

  private get banners(): any[] {
    // @ts-ignore
    const banners = this.data?.results[0]?.bannerFiles as Banner[];
    return banners?.map((banner) => ({
      ...banner,
      date:
        banner?.activeFrom && banner?.activeTo
          ? `${formatDate(banner?.activeFrom as string)} - ${formatDate(banner?.activeTo as string)}`
          : banner?.activeFrom
            ? formatDate(banner?.activeFrom as string)
            : null,
    }));
  }

  private get slides(): any[] {
    // @ts-ignore
    const results = this.data?.results as ProductResults[];
    const allProducts = results
      .flatMap((result) => result.products)
      .map((product) => {
        const photos = plainToInstance(Photo, product?.photos as Photo[]);
        return {
          ...product,
          photos: product?.photos?.map((photo) =>
            plainToInstance(Photo, photo),
          ),
          backgroundImage: photos.find((photo) => photo.backgroundImage)?.file
            ?.url,
        };
      });
    const videoItems = results
      .filter((result) => result.videoUrl)
      .map((result) => ({
        videoUrl: result.videoUrl,
        mobileVideoUrl: result.mobileVideoUrl,
        videoBackground: result.videoBackground?.url,
        mobileVideoBackground: result.mobileVideoBackground?.url,
        redirectUrl: result.redirectUrl,
        backgroundImage: '',
        photos: [],
      }));
    allProducts.unshift(...videoItems);
    return allProducts;
  }
}
